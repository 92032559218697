import React, { useEffect, useState } from "react"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import { useStaticQuery } from "gatsby"
import Contact from "../components/Contact"
import ArticleCard from "../components/ArticleCard"
import ReactPaginate from "react-paginate"
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa"
import { graphql } from "gatsby"
import jinjibu_img from "../images/jinjibu/jinjibu-img.png"
import { Seo } from "../components/seo"

const Jinjibu = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiTitles(
        sort: { order: DESC, fields: DatePublication }
        filter: {
          article_categories: {
            elemMatch: { Name: { eq: "メキシコの人事部" } }
          }
        }
      ) {
        nodes {
          Content
          Title
          Description
          Slug
          Image {
            url
          }
          LastUpdate
          DatePublication
          article_categories {
            Name
            Slug
          }
        }
      }
    }
  `)
  const dataArticles = query.allStrapiTitles.nodes
  const [postsPerPage] = useState(10)
  const [offset, setOffset] = useState(1)
  const [allDataArticle, setAllDataArticle] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [selectedPage, setSelectedPage] = useState(1)

  const getPostData = data => {
    return data.map(mexar => <ArticleCard article={mexar} />)
  }

  const getAllPosts = async () => {
    const data = await dataArticles
    const indexLast = postsPerPage * selectedPage
    const indexFirst = indexLast - postsPerPage
    const slice = data.slice(indexFirst, indexLast)
    const postData = getPostData(slice)
    setAllDataArticle(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  }

  const handlePageClick = e => {
    setSelectedPage(e.selected + 1)
    setOffset(selectedPage * 10)
  }

  useEffect(() => {
    getAllPosts()
    window.scrollTo(0, 0) //Do scroll up when press the number button
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])
  return (
    <>
      <section className="container jinjibu">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="jinjibu__content">
              <SectionTitle>メキシコの人事部</SectionTitle>
              <hr className="u-line-bottom" />
              <img
                className="jinjibu-img"
                src={jinjibu_img}
                alt="jinjibu-img"
              />
              <p>
                クイックグループでは世界の人事部として、世界で活躍する日本のグローバル企業の
                人材採用サポートを行っております。人材採用から人事・労務に関しての課題解決のため、
                すこしでも有益な情報をお伝えできればと、この度「メキシコの人事部」発行の運びとなりました。
                メキシコの労務、法務、税務等の専門家にご協力いただき、皆様に有益な情報をお届けしてまいります。
                <br />
                配信をご希望の方は下記よりお問合せください。
                <br />
              </p>
              <p>
                メキシコの人事部配信希望の方は
                <a
                  href="https://lb.benchmarkemail.com//listbuilder/signupnew?IkfHTmyPVq9eZfW00cP67%252F5pwVnAjsSIVxcWlUDC2zrtO5iNRn8gS8X4lBTFzgEtozrTjFZYUg4%253D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  コチラ
                </a>
                <br />
                アメリカの人事部配信希望の方は
                <a
                  href="https://www.919usa.com/subscribeNewsletter.aspx?type=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  コチラ
                </a>
                <br />
                イギリスの人事部配信希望の方は
                <a
                  href="https://www.centrepeople.com/newsletter/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  コチラ
                </a>
              </p>
              <hr className="u-line-bottom u-space" />
              {allDataArticle}
              <ReactPaginate
                previousLabel={<FaAngleDoubleLeft />}
                nextLabel={<FaAngleDoubleRight />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"content-pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            <Contact lang={"ja"} />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}
export default Jinjibu


export const Head = () => {
  return (
    <Seo
      title="メキシコの人事部"
      description="クイックグループでは世界の人事部として、世界で活躍する日本のグローバル企業の 人材採用サポートを行っております。人材採用から人事・労務に関しての課題解決のため、 すこしでも有益な情報をお伝えできればと、この度「メキシコの人事部」発行の運びとなりました。 メキシコの労務、法務、税務等の専門家にご協力いただき、皆様に有益な情報をお届けしてまいります。"
      url={`https://www.919mexico.com/mexico-jinjibu`}
    />
  )
}
